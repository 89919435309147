import React from "react"
import * as styles from "./Pagination.module.scss"

const Pagination = ({ count, sectionIndex, setSectionIndex }) => {
  const countArray = Array.from(new Array(count)).map((value, index) => index)

  return (
    <div className={styles.container}>
      {countArray.map((item, index) => {
        return (
          <button
            onClick={() => {
              setSectionIndex(index)
            }}
            className={index === sectionIndex ? styles.active : ""}
            title={`slide ${index}`}
            key={`slide-pagination-${index}`}
          >
            <span>{index}</span>
          </button>
        )
      })}
    </div>
  )
}

export default Pagination
