import React, { useRef, useEffect } from "react"
import { useFrame } from "react-three-fiber"
import { useTexture } from '@react-three/drei';
import { Vector2 } from "three"

import useAspect from '../../../../hooks/useAspect';

import testVertexShader from "../../../../shaders/test/vertex.glsl"
import testFragmentShader from "../../../../shaders/test/fragment.glsl"

function AnimatedPlane({
  foregroundSize,
  sectionIndex,
  timerRunning,

  videoTextures,
  foregroundImage,
  backgroundImage
}) {
  const meshRef = useRef()
  const planeRef = useRef()
  const timeRef = useRef(0)
  const materialRef = useRef()

  useFrame((state, delta) => {
    if (meshRef.current && timerRunning) {
      if (meshRef.current.material.uniforms.uTime.value <= 2 / speed) {
        meshRef.current.material.uniforms.uTime.value += delta
        timeRef.current = meshRef.current.material.uniforms.uTime.value
      }
    }
  })

  useEffect(() => {
    timeRef.current = 0
    if (meshRef.current) {
      meshRef.current.material.uniforms.uTime.value = 0
    }
  }, [sectionIndex])

  const speed = 0.8

  const [width, height] = useAspect(foregroundSize.width, foregroundSize.height)

  const [foregroundTexture, backgroundTexture] = useTexture([foregroundImage?.asset.fluid.src, backgroundImage?.asset.fluid.src]);

  return (
    <mesh ref={meshRef} scale={[width, height, 1]}>
      <planeBufferGeometry args={[1, 1, 64, 64]} ref={planeRef} />
      <shaderMaterial
        ref={materialRef}
        depthTest={false}
        args={[
          {
            vertexShader: testVertexShader,
            fragmentShader: testFragmentShader,
            // wireframe: true,
            uniforms: {
              uTime: { value: timeRef.current },
              uTexture: { value: foregroundTexture },
              uTextureBackground: { value: backgroundTexture },
              uTexture: { value: videoTextures[sectionIndex]?.foregroundTexture || foregroundTexture},
              uTextureBackground: { value: videoTextures[sectionIndex]?.backgroundTexture || backgroundTexture},
              uSpeed: { value: speed },
              uAspectRatio: { value: 1.777 },
              uDimensions: { value: new Vector2(width, height) },
            },
          },
        ]}
      />
    </mesh>
  )
}

export default AnimatedPlane;