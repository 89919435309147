// Convet PortableText to plaintext.
// https://www.sanity.io/docs/presenting-block-text

export default function toPlainText(blocks = []) {
  return blocks
    .map(block => {
      if (block._type !== "block" || !block.children) {
        return ""
      }
      return block.children.map(child => child.text).join("")
    })
    .join(" ")
}
