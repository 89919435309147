import { useThree } from 'react-three-fiber';

const useAspect = (width, height, factor = 1) => {
  const { viewport: v, aspect } = useThree()
  const adaptedHeight =
    height * (aspect > width / height ? v.width / width : v.height / height)
  const adaptedWidth =
    width * (aspect > width / height ? v.width / width : v.height / height)
  return [adaptedWidth * factor, adaptedHeight * factor, 1]
}

export default useAspect;