import * as styles from "./RippleButton.module.scss"

import React, { useEffect, useRef, useState } from "react"

import { Button } from "dnb-ui-lib"
import classNames from "classnames"
import { navigate } from "gatsby"

const RippleButton = ({
  sectionCount,

  transitionRunning,
  setTransitionRunning,

  sectionIndex,
  setSectionIndex,

  isRevealed,
  setIsRevealed,
}) => {
  const [buttonVisible, setButtonVisible] = useState(true)
  const [animationStage, setAnimationStage] = useState(null)

  useEffect(() => {
    if (isRevealed) {
      setTransitionRunning(false)
    }
  }, [isRevealed])

  useEffect(() => {
    setIsRevealed(false)
  }, [sectionIndex])

  useEffect(() => {
    setButtonVisible(true)
  }, [sectionIndex])

  const effectCallback = () => {
    let animationType = "pulse"
    if (!isRevealed && transitionRunning) {
      animationType = "out"
    }
    setAnimationStage(animationType)
  }

  useEffect(effectCallback, [transitionRunning, isRevealed])

  return (
    <div className={styles.container}>
      {sectionIndex < sectionCount ? (
        <Button
          className={classNames(styles.nextButton, {
            [styles.visible]: isRevealed && buttonVisible,
          })}
          text="Neste"
          variant="signal"
          onClick={() => {
            setButtonVisible(false)
            setSectionIndex(sectionIndex + 1)
          }}
        />
      ) : (
        <Button
          className={classNames(styles.nextButton, styles.visible)}
          text="Tilbake til forside"
          variant="signal"
          onClick={() => {
            navigate("/")
          }}
        />
      )}

      <button
        onClick={() => setTransitionRunning(true)}
        className={classNames(styles.rippleButton, {
          [styles.hidden]:
            isRevealed ||
            sectionIndex >= sectionCount ||
            animationStage === "out",
        })}
      >
        <span className={styles.loadingGraphic}>
          <svg
            viewBox="0 0 115 115"
            fill="none"
            className={classNames(styles.ring, styles.ring3)}
          >
            <circle
              cx={57.5}
              cy={57.5}
              r={54.5}
              stroke="#FDBB31"
              strokeWidth={5}
            />
          </svg>
          <svg
            viewBox="0 0 115 115"
            fill="none"
            className={classNames(styles.ring, styles.ring2)}
          >
            <path
              d="M3 57.5C3 65.0768 4.46219 72.2549 7.25365 78.7683C10.0451 85.2817 14.0329 91.1305 18.9512 96.0488C23.8695 100.967 29.7183 104.955 36.2317 107.746C42.7451 110.538 49.9232 112 57.5 112C65.0768 112 72.2549 110.538 78.7683 107.746C85.2817 104.955 91.1305 100.967 96.0488 96.0488C100.967 91.1305 104.955 85.2817 107.746 78.7683C110.538 72.2549 112 65.0768 112 57.5C112 49.9232 110.538 42.7451 107.746 36.2317C104.955 29.7183 100.967 23.8695 96.0488 18.9512C91.1305 14.0329 85.2817 10.0451 78.7683 7.25366C72.2549 4.4622 65.0768 3 57.5 3"
              stroke="#FDBB31"
              strokeWidth="5"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
          </svg>
          <svg
            viewBox="0 0 115 115"
            fill="none"
            className={classNames(styles.ring, styles.ring1)}
          >
            <path
              d="M57.5 112C64.9992 112 72.2368 110.518 78.6896 107.727C85.1424 104.937 91.072 101.013 96.0424 96.0424C101.013 91.072 104.937 85.2296 107.727 78.6896C110.518 72.1496 112 64.9992 112 57.5C112 50.0008 110.518 42.7632 107.727 36.3104C104.937 29.8576 101.013 23.928 96.0424 18.9576C91.072 13.9872 85.2296 10.0632 78.6896 7.2728C72.1496 4.4824 64.9992 3 57.5 3C50.0008 3 42.7632 4.4824 36.3104 7.2728C29.8576 10.0632 23.928 13.9872 18.9576 18.9576C13.9872 23.928 10.0632 29.7704 7.2728 36.3104C4.4824 42.8504 3 50.0008 3 57.5C3 64.9992 4.4824 72.2368 7.2728 78.6896C10.0632 85.1424 13.9872 91.072 18.9576 96.0424"
              stroke="#FDBB31"
              strokeWidth="5"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
          </svg>
        </span>

        <span
          className={`${styles.text} ${
            isRevealed ? "" : !transitionRunning ? styles.visibleButtonText : ""
          }`}
        >
          Klikk for å se ringvirkningene
        </span>
      </button>

      <div
        className={classNames(styles.transitionLayer, {
          [styles.show]: animationStage === "out",
        })}
      >
        <span className={styles.transitionGraphic}>
          <svg
            onAnimationEnd={() => {
              if (!isRevealed) {
                setIsRevealed(true)
                setAnimationStage("pulse")
              }
            }}
            viewBox="0 0 115 115"
            fill="none"
            className={classNames(styles.ring, styles.ring3)}
          >
            <circle
              cx={57.5}
              cy={57.5}
              r={54.5}
              stroke="#FDBB31"
              strokeWidth={5}
            />
          </svg>
          <svg
            viewBox="0 0 115 115"
            fill="none"
            className={classNames(styles.ring, styles.ring2)}
          >
            <path
              d="M3 57.5C3 65.0768 4.46219 72.2549 7.25365 78.7683C10.0451 85.2817 14.0329 91.1305 18.9512 96.0488C23.8695 100.967 29.7183 104.955 36.2317 107.746C42.7451 110.538 49.9232 112 57.5 112C65.0768 112 72.2549 110.538 78.7683 107.746C85.2817 104.955 91.1305 100.967 96.0488 96.0488C100.967 91.1305 104.955 85.2817 107.746 78.7683C110.538 72.2549 112 65.0768 112 57.5C112 49.9232 110.538 42.7451 107.746 36.2317C104.955 29.7183 100.967 23.8695 96.0488 18.9512C91.1305 14.0329 85.2817 10.0451 78.7683 7.25366C72.2549 4.4622 65.0768 3 57.5 3"
              stroke="#FDBB31"
              strokeWidth="5"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
          </svg>
          <svg
            viewBox="0 0 115 115"
            fill="none"
            className={classNames(styles.ring, styles.ring1)}
          >
            <path
              d="M57.5 112C64.9992 112 72.2368 110.518 78.6896 107.727C85.1424 104.937 91.072 101.013 96.0424 96.0424C101.013 91.072 104.937 85.2296 107.727 78.6896C110.518 72.1496 112 64.9992 112 57.5C112 50.0008 110.518 42.7632 107.727 36.3104C104.937 29.8576 101.013 23.928 96.0424 18.9576C91.072 13.9872 85.2296 10.0632 78.6896 7.2728C72.1496 4.4824 64.9992 3 57.5 3C50.0008 3 42.7632 4.4824 36.3104 7.2728C29.8576 10.0632 23.928 13.9872 18.9576 18.9576C13.9872 23.928 10.0632 29.7704 7.2728 36.3104C4.4824 42.8504 3 50.0008 3 57.5C3 64.9992 4.4824 72.2368 7.2728 78.6896C10.0632 85.1424 13.9872 91.072 18.9576 96.0424"
              stroke="#FDBB31"
              strokeWidth="5"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
          </svg>
        </span>
      </div>
    </div>
  )
}

export default RippleButton
