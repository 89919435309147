import React, { Suspense } from "react"
import { Canvas } from "react-three-fiber"

import AnimatedPlane from "./AnimatedPlane/AnimatedPlane"

const ThreeJsScene = ({ timerRunning, sectionIndex, data, videoTextures }) => {
  if (videoTextures.length <= 0) {
    return null
  }

  return (
    <>
      <Canvas camera={{ fov: 50, position: [0, 0, 30] }}>
        <Suspense fallback={null}>
          <AnimatedPlane
            timerRunning={timerRunning}
            sectionIndex={sectionIndex}
            foregroundSize={
              data.visualiserImageOne.image.asset.metadata.dimensions
            }
            videoTextures={videoTextures}
            foregroundImage={data.visualiserImageOne.image}
            backgroundImage={data.visualiserImageTwo.image}
          />
        </Suspense>
      </Canvas>
      {/* <div className={`${styles.overlay} ${loadingTexture && styles.loading}`}></div> */}
    </>
  )
}

export default ThreeJsScene
