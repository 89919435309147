import { useState, useEffect } from "react"
import { VideoTexture } from "three"
import createDomVideoElement from "../utils/createDomVideoElement"

const createVisualiserSection = section => {
  const foregroundVideo = createDomVideoElement(section.foreground)
  const backgroundVideo = createDomVideoElement(section.background)

  const foregroundTexture = new VideoTexture(foregroundVideo)
  const backgroundTexture = new VideoTexture(backgroundVideo)

  const play = () => {
    foregroundVideo.play()
    backgroundVideo.play()
  }

  const pause = () => {
    foregroundVideo.pause()
    backgroundVideo.pause()
  }

  return {
    foregroundVideo,
    backgroundVideo,

    foregroundTexture,
    backgroundTexture,

    play,
    pause,
  }
}

const useVideoTextures = data => {
  const [videoTextures, setVideoTextures] = useState([])
  // const [videoTexturesPlaying]

  useEffect(() => {
    let videosArray = data.map(createVisualiserSection)

    setVideoTextures(videosArray)
  }, [data]);

  const videoTexturesPlaying = () => {
    let playing = false; 

    videoTextures.forEach(videoTexture => {
      if (!videoTexture.foregroundVideo.paused || !videoTexture.backgroundVideo.paused) {
        playing = true;
      }
    })

    return playing;
  }

  return [videoTextures, videoTexturesPlaying]
}

export default useVideoTextures
