import React, { useEffect, useRef, useState } from "react"

import BackgroundImage from "gatsby-background-image"
import ContentContainer from "../content-container/ContentContainer"
import FallbackImages from './FallbackImages/FallbackImages';
import Img from "gatsby-image"
import Pagination from "./Pagination/Pagination"
import PortableText from "@sanity/block-content-to-react"
import RippleButton from "./RippleButton/RippleButton"
import Section from "./Section/Section"
import ThreeJsScene from "./ThreeJsScene/ThreeJsScene"
import VideoPlayer from "../video-player/VideoPlayer"
import classNames from "classnames"
import * as styles from "./Visualiser.module.scss"
import toPlainText from "../../utils/toPlainText"
import { track } from "../../utils/track"

const Visualiser = ({
  visualizerData,
  visualiserEnd,
  hidden,
  videoTextures,
  videoTexturesPlaying,
  started,
}) => {
  const [sectionIndex, setSectionIndex] = useState(0)
  const [transitionRunning, setTransitionRunning] = useState(false)

  const [isRevealed, setIsRevealed] = useState(false)
  const [isUnrevealed, setIsUnrevealed] = useState(true)

  const previousSectionIndexRef = useRef(null)
  const containerRef = useRef()
  const containerAnimationRef = useRef()

  useEffect(() => {
    // console.log('started : ', started);
    // console.log('playing', videoTexturesPlaying())
    const texturesPlaying = videoTexturesPlaying()
    if (started && !texturesPlaying) {
      if (videoTextures.length > 0) {
        // console.log('autoplay')
        videoTextures[sectionIndex]?.play()
      }
    }
  }, [started, videoTextures, sectionIndex])

  useEffect(() => {
    return () => {
      if (videoTextures.length > 0) {
        videoTextures[sectionIndex]?.pause()
      }
    }
  }, [sectionIndex, videoTextures])

  useEffect(() => {
    setIsUnrevealed(true)
  }, [sectionIndex])

  useEffect(() => {
    if (sectionIndex < visualizerData.length) {
      if (!hidden) {
        track(
          "Video",
          "Video Loaded",
          toPlainText(visualizerData[sectionIndex]._rawVisualiserTekstOne),
          null
        )
      }
    }
  }, [sectionIndex, hidden])

  useEffect(() => {
    if (sectionIndex < visualizerData.length) {
      if (transitionRunning && isUnrevealed) {
        track(
          "Video",
          "Play",
          toPlainText(visualizerData[sectionIndex]._rawVisualiserTekstOne),
          null
        )
        track(
          "Video",
          "Video Completed 100%",
          toPlainText(visualizerData[sectionIndex]._rawVisualiserTekstOne),
          null
        )
        setIsUnrevealed(false)
      }
    }
  }, [transitionRunning])

  useEffect(() => {
    containerAnimationRef.current = containerRef.current.animate(
      [{ opacity: 0 }, { opacity: 1 }],
      { duration: 1000, fill: "both" }
    )
    containerAnimationRef.current.pause()
  }, [])

  useEffect(() => {
    if (!hidden) {
      containerAnimationRef.current.play()
    }
  }, [hidden])

  const playVideos = index => {
    if (index < visualizerData.length) {
      videoTextures[index]?.play()
    }
  }

  const updateSectionIndex = newIndex => {
    let transitionDuration = 800
    containerRef.current.animate(
      [
        { opacity: 1 },
        { opacity: 0, offset: 0.5 },
        { opacity: 0, offset: 0.6 },
        { opacity: 1 },
      ],
      { duration: transitionDuration, fill: "both" }
    )

    setTimeout(() => {
      previousSectionIndexRef.current = sectionIndex
      setSectionIndex(newIndex)
      playVideos(newIndex)
    }, transitionDuration * 0.5)
  }

  return (
    <>
      <div className={styles.background}></div>

      <article
        className={classNames(styles.wrapper, { [styles.noTouch]: hidden })}
        ref={containerRef}
      >
        {sectionIndex >= visualizerData.length ? (
          <div className={classNames(styles.container, styles.endScreen)}>
            {visualiserEnd.visualiserEndImage.video ? (
              <div>
                <VideoPlayer
                  className={styles.video}
                  asset={visualiserEnd.visualiserEndImage.video.asset}
                  isBackgroundVideo={true}
                />
                <div className={styles.videoFilter} />
                <ContentContainer>
                  <div className={styles.overlay}></div>
                  <PortableText
                    blocks={visualiserEnd._rawVisualiserEndText}
                    className={styles.endScreenText}
                    renderContainerOnSingleChild={true}
                  />
                </ContentContainer>
              </div>
            ) : (
              <>
                <BackgroundImage
                  Tag="section"
                  fluid={visualiserEnd.visualiserEndImage.image.asset.fluid}
                  className={styles.backgroundImage}
                >
                  <ContentContainer>
                    <div className={styles.overlay}></div>
                    <PortableText
                      blocks={visualiserEnd._rawVisualiserEndText}
                      className={styles.endScreenText}
                      renderContainerOnSingleChild={true}
                    />
                  </ContentContainer>
                </BackgroundImage>
              </>
            )}
          </div>
        ) : (
          <div className={styles.container}>
            {/* <FallbackImages 
              imageOne={visualizerData[sectionIndex].visualiserImageOne} 
              imageTwo={visualizerData[sectionIndex].visualiserImageTwo} 
              imageOneVisible={isUnrevealed && !transitionRunning}
              imageTwoVisible={isRevealed || transitionRunning}
            /> */}

            <ThreeJsScene
              timerRunning={transitionRunning}
              sectionIndex={sectionIndex}
              data={visualizerData[sectionIndex]}
              videoTextures={videoTextures}
            />

            {/* <div className={styles.overlay}></div> */}

            <Section
              data={visualizerData[sectionIndex]}
              previousSectionIndex={previousSectionIndexRef.current}
              sectionIndex={sectionIndex}
              isRevealed={isRevealed}
              isUnrevealed={isUnrevealed}
              transitionRunning={transitionRunning}
              setTransitionRunning={setTransitionRunning}
            />
          </div>
        )}

        <RippleButton
          sectionCount={visualizerData.length}
          transitionRunning={transitionRunning}
          setTransitionRunning={setTransitionRunning}
          sectionIndex={sectionIndex}
          setSectionIndex={updateSectionIndex}
          isRevealed={isRevealed}
          setIsRevealed={setIsRevealed}
          videoTextures={videoTextures}
        />

        <Pagination
          count={visualizerData.length}
          sectionIndex={sectionIndex}
          setSectionIndex={updateSectionIndex}
        />
        <p className={styles.hashtag}>#havgapet</p>
      </article>
    </>
  )
}

export default Visualiser
