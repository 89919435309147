import React from "react"

const serializers = {
  marks: {
    mark: ({ children }) => {
      return <mark>{children}</mark>
    },
  },
}

export default serializers
