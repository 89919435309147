/**
 * Creates a new video element with the specifed path as a src attribute 
 * @param {string} path 
 * @returns 
 */
const createDomVideoElement = path => {
  const video = document.createElement("video")
  video.crossOrigin = "Anonymous"
  video.loop = true
  video.muted = true
  video.playsInline = true
  video.src = path

  // video.addEventListener('play', () => {
  //   console.log('playing', path);
  // })

  // video.addEventListener('pause', () => {
  //   console.log('pausing', path)
  // })
  // vid.play()

  // Using HLS:
  // const videoSrc = `https://stream.mux.com/${id}.m3u8`;
  // if (Hls.isSupported()) {
  //   var hls = new Hls();
  //   hls.loadSource(videoSrc);
  //   hls.attachMedia(vid);
  // } else  if (vid.canPlayType("application/vnd.apple.mpegurl")) {
  //   vid.src = videoSrc
  // }

  return video
}

export default createDomVideoElement;