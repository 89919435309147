import { Button, Heading } from "dnb-ui-lib/components"
import { StaticQuery, graphql } from "gatsby"

import BackgroundImage from "gatsby-background-image"
import ContentContainer from "../content-container/ContentContainer"
import { PropTypes } from "prop-types"
import React from "react"
import ScrollInstruction from "../scroll-instruction/ScrollInstruction"
import VideoPlayer from "../video-player/VideoPlayer"
import classNames from "classnames"
import * as styles from "./VisualiserHero.module.scss"

/**
 * Returns a component used as a hero styled article-link on the front-page
 * @param {title} string Specifies the title used in the hero
 * @param {subtitle} string Added underneith the title field
 * @param {image} string Specifies the image to be used as a background
 * @param {cta} string Holds the link/slug and a button text for the link
 *
 */

const VisualiserHero = ({ location, ctaClickHandler, fullScreen }) => {
  return (
    <StaticQuery
      query={graphql`
        {
          allSanityVisualiser {
            edges {
              node {
                visualiserStart {
                  title
                  visualiserStartImage {
                    video {
                      _key
                      _type
                      asset {
                        assetId
                        thumbTime
                        status
                        playbackId
                        filename
                        _type
                        _key
                      }
                    }
                    image {
                      alt
                      asset {
                        fluid(maxWidth: 2000) {
                          ...GatsbySanityImageFluid
                        }
                      }
                    }
                  }
                  visualiserStartLinkText
                  visualiserStartSubtitle
                  visualiserStartLink {
                    ... on SanityArticleFeature {
                      id
                      slug {
                        current
                      }
                    }
                    ... on SanityArticleBasic {
                      id
                      slug {
                        current
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <VisualiserHeroComponent
          data={data}
          location={location}
          ctaClickHandler={ctaClickHandler}
          fullScreen={fullScreen}
        />
      )}
    />
  )
}

const VisualiserHeroComponent = ({
  data,
  location,
  ctaClickHandler,
  fullScreen,
}) => {
  // console.log('location : ', location);
  const heroData = data.allSanityVisualiser.edges[0].node.visualiserStart
  const video = heroData.visualiserStartImage.video

  return (
    <div className={classNames({ [styles.wrapper]: fullScreen })}>
      {video ? (
        <div
          className={classNames(styles.hero, {
            [styles.fullScreen]: fullScreen,
          })}
        >
          <VideoPlayer
            className={styles.video}
            asset={video.asset}
            isBackgroundVideo={true}
          />
          <div className={styles.videoFilter} />
          <ContentContainer>
            <div className={styles.heroContent}>
              <Heading className={styles.title}>{heroData.title}</Heading>
              <p>{heroData.visualiserStartSubtitle}</p>

              <Button
                variant="signal"
                text={heroData.visualiserStartLinkText}
                onClick={ctaClickHandler}
              />
            </div>
            {location &&
            location.state &&
            location.state.skipIntro === false ? null : (
              <ScrollInstruction />
            )}
          </ContentContainer>
        </div>
      ) : (
        <BackgroundImage
          Tag="section"
          className={classNames(styles.hero, {
            [styles.fullScreen]: fullScreen,
          })}
          fluid={heroData.visualiserStartImage.image.asset.fluid}
          backgroundColor={`#040e18`}
        >
          <div className={styles.filter}>
            <ContentContainer>
              <div className={styles.heroContent}>
                <Heading className={styles.title}>{heroData.title}</Heading>
                <p>{heroData.visualiserStartSubtitle}</p>

                <Button
                  variant="signal"
                  text={heroData.visualiserStartLinkText}
                  onClick={ctaClickHandler}
                />
              </div>
              {location &&
              location.state &&
              location.state.skipIntro === false ? null : (
                <ScrollInstruction />
              )}
            </ContentContainer>
          </div>
        </BackgroundImage>
      )}
    </div>
  )
}

VisualiserHero.propTypes = {
  link: PropTypes.object,
  fullScreen: PropTypes.bool,
}

VisualiserHero.defaultProps = {
  link: null,
  fullScreen: false,
}

export default VisualiserHero
