import * as styles from "./Section.module.scss"

import React, { useEffect, useLayoutEffect, useRef } from "react"

import PortableText from "@sanity/block-content-to-react"
import serializers from "../../../utils/serializer"

const keyframes = [
  {
    // clipPath: "circle(0%)",
    opacity: 0,
  },
  {
    // clipPath: "circle(100%)",
    opacity: 1,
  },
]

const timing = {
  duration: 2050,
  fill: "both",
  delay: 300,
}

const foreGroundKeyframes = [{ opacity: 1 }, { opacity: 0 }]

const Section = ({ data, transitionRunning, isUnrevealed, sectionIndex }) => {
  const foregroundTextRef = useRef()
  const backgroundTextRef = useRef()
  const backgroundTextAnimationRef = useRef()
  const foregroundTextAnimationRef = useRef()

  useLayoutEffect(() => {
    foregroundTextAnimationRef.current = foregroundTextRef.current.animate(
      foreGroundKeyframes,
      { duration: 1000, fill: "forwards" }
    )
    foregroundTextAnimationRef.current.pause()

    backgroundTextAnimationRef.current = backgroundTextRef.current.animate(
      keyframes,
      timing
    )
    backgroundTextAnimationRef.current.pause()
  }, [])

  useLayoutEffect(() => {
    if (transitionRunning) {
      backgroundTextAnimationRef.current.play()
      foregroundTextAnimationRef.current.play()
    }
  }, [transitionRunning])

  useEffect(() => {
    if (isUnrevealed) {
      backgroundTextAnimationRef.current.cancel()
      foregroundTextAnimationRef.current.cancel()
    }
  }, [isUnrevealed])

  return (
    <section className={styles.wrapper}>
      <div ref={foregroundTextRef}>
        <PortableText
          blocks={data._rawVisualiserTekstOne}
          serializers={serializers}
          className={styles.texts}
        />
      </div>

      <div ref={backgroundTextRef} className={styles.backgroundText}>
        <PortableText
          blocks={data._rawVisualiserTekstTwo}
          serializers={serializers}
          className={styles.texts}
        />
      </div>

      {sectionIndex === 0 && isUnrevealed && (
        <span className={styles.disclaimer}>
          *Eksemplene er hypotetiske utfall der de forutsettes at statens
          utgifter ikke kuttes eller ikke finansieres på andre vis.
        </span>
      )}
    </section>
  )
}

export default Section
