import React, { useEffect, useState } from "react"

import Layout from "../components/layout/Layout"
import SEO from "../components/seo/Seo"
import Visualiser from "../components/visualiser/Visualiser"
import VisualiserHero from "../components/visualiser-hero/VisualiserHero"
import { graphql } from "gatsby"
// import useVideoTextures from '../../hooks/useVideoTextures';
import useVideoTextures from "../hooks/useVideoTextures"
import visualiserVideos from "../data/visualiser-videos"

const TestPage = ({ data, location }) => {
  const { visualiser, visualiserEnd } = data.allSanityVisualiser.edges[0].node

  const [skipIntro, setSkipIntro] = useState(location.state?.skipIntro)
  const [videoTextures, videoTexturesPlaying] = useVideoTextures(
    visualiserVideos
  )

  useEffect(() => {
    // console.log('skipIntro : ', skipIntro);

    if (typeof window !== `undefined`) {
      // console.log('history state:', window.history.state);
      window.history.replaceState({ skipIntro: false }, "")
      // console.log('history state:', window.history.state);
    }
  }, [skipIntro])

  return (
    <Layout overlay={true}>
      <SEO title="Havgapet" />
      <Visualiser
        visualizerData={visualiser}
        visualiserEnd={visualiserEnd}
        videoTextures={videoTextures}
        videoTexturesPlaying={videoTexturesPlaying}
        hidden={!skipIntro}
        started={skipIntro}
      />
      {!skipIntro && (
        <VisualiserHero
          location={location}
          ctaClickHandler={() => {
            const firstVideos = videoTextures[0]
            if (firstVideos) {
              firstVideos.play()
            }
            setSkipIntro(true)
          }}
          fullScreen={true}
        />
      )}
    </Layout>
  )
}

export const query = graphql`
  {
    allSanityVisualiser {
      edges {
        node {
          id
          visualiser {
            visualiserImageOne {
              image {
                asset {
                  fluid(maxWidth: 3000) {
                    ...GatsbySanityImageFluid
                  }
                  metadata {
                    dimensions {
                      height
                      width
                    }
                  }
                }
                alt
              }
              video {
                _key
                _type
                asset {
                  assetId
                  thumbTime
                  status
                  playbackId
                  filename
                  _type
                  _key
                }
              }
            }
            visualiserImageTwo {
              image {
                asset {
                  fluid(maxWidth: 3000) {
                    ...GatsbySanityImageFluid
                  }
                  metadata {
                    dimensions {
                      height
                      width
                    }
                  }
                }
                alt
              }
              video {
                _key
                _type
                asset {
                  assetId
                  thumbTime
                  status
                  playbackId
                  filename
                  _type
                  _key
                }
              }
            }
            _rawVisualiserTekstOne(resolveReferences: { maxDepth: 10 })
            _rawVisualiserTekstTwo(resolveReferences: { maxDepth: 10 })
          }
          visualiserEnd {
            _key
            _type
            _rawVisualiserEndImage(resolveReferences: { maxDepth: 10 })
            _rawVisualiserEndText(resolveReferences: { maxDepth: 10 })
            visualiserEndImage {
              image {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
                alt
              }
              video {
                _key
                _type
                asset {
                  assetId
                  thumbTime
                  status
                  playbackId
                  filename
                  _type
                  _key
                }
              }
            }
          }
        }
      }
    }
  }
`

export default TestPage
