export default [
  {
    id: 1,
    foreground: "/videos/boat-a.mp4",
    background: "/videos/boat-b.mp4",
  },
  {
    id: 2,
    foreground: "/videos/shipping-a.mp4",
    background: "/videos/shipping-b.mp4",
  },
  {
    id: 3,
    foreground: "/videos/fish-a.mp4",
    background: "/videos/fish-b.mp4",
  },
  {
    id: 4,
    foreground: "/videos/bergen-a.mp4",
    background: "/videos/bergen-b.mp4",
  },
  {
    id: 5,
    foreground: "/videos/mayor-a.mp4",
    background: "/videos/mayor-b.mp4",
  },
  // {
  //   id: 7,
  //   foreground: '/videos/london-a.mp4',
  //   background: '/videos/london-b.mp4'
  // }
]
